import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { BootstrapVue3 } from 'bootstrap-vue-3'


// Import Bootstrap an BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const socket = io(process.env.VUE_APP_SOCKET_SERVER)

const app = createApp(App)
app.use(router)
app.use(VueSocketIOExt, socket)
app.use(BootstrapVue3)
app.mount('#app');
