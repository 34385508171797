<template>
  <h1>Welcome to the Game</h1>
</template>

<script>
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Game',
  components: {
    //HelloWorld
  }
}
</script>
