<template>
  <div>
    <!-- <p v-if="$socket.connected">We're connected to the server!</p> -->
    <h2>Room ID: {{ room.id }}</h2>
    <b-button variant="primary" @click="copyUrl()">Copy URL</b-button>
    <b-button :variant="room.isLocked ? 'danger' : 'success'" @click="modifiedRoomLock" :disabled='!me.isOwner'>{{ room.isLocked ? "Locked 🔒": "Unlocked 🔓"}}</b-button>
    <b-alert v-model="showError" variant="danger" dismissible>{{ errorMessage }}</b-alert>
    <div v-for="player in players" :key="player.username">
      <PlayerInRoom :isMe="player.username === username" :imOwner="me.isOwner" :player="player" @changedData="changedData" @kickedPlayer="kickedPlayer"/>
    </div>
  </div>
</template>

<script>
import PlayerInRoom from '@/components/PlayerInRoom.vue'

export default {
  name: "Room",
  components: {PlayerInRoom},

  data() {
    return {
      fromHomePage: false,
      username: '',
      room: {id: undefined},
      players: {},
      me: {},
      showError: false,
      errorMessage: '',
    };
  },

  sockets: {
    connect() {
      console.log("Connected...")
      // if we come from direct Url we ask for username and send Join
      if(!this.fromHomePage){
        this.username = prompt("Enter your username", "username...")
        if(this.username) this.$socket.client.emit("joinRoom", this.username, this.room.id)
        else ; // WIP empty username
      }
    },

    joinRoom(room, players) {
      this.room = room
      this.players = players
      this.me = players[this.username]
      //console.log("players="+JSON.stringify(this.players))
    },

    errorMessage(errorMessage){
      console.log("<ERROR>: "+errorMessage)
      this.$router.push({name: "Home", params: { errorMessage: errorMessage }});
    },

    modifiedPlayer(player) {
      console.log("playerData for <" + player.username + "> modified !");
      this.players[player.username] = player;
      this.me = this.players[this.username]
    },

    modifiedRoomLock(isLocked){
      this.room.isLocked = isLocked
    },

    kickedPlayer(username){
      if(username === this.username){
        console.log("You were kicked")
        this.$router.push({ name: 'Home', params: {errorMessage: "You were kicked from the room"}})
      }
      else{
        console.log(username+" was kicked from the room")
        this.showError = true,
        this.errorMessage = username+" was kicked from the room"
        delete this.players[username]
      }
    },

    playerDisconnected(username){
      console.log(username + " Disconnected !")
      delete this.players[username]
    },

    enterGame(){
      console.log("ENTER GAME !!!")
      this.$router.push({ name: 'Game', params: {} })
    },
  },

  methods: {
    copyUrl() {
      navigator.clipboard.writeText(window.location.href);
    },
    changedData(){
      console.log("EMIT(MODIFIED_PLAYER): "+JSON.stringify(this.me))
      this.$socket.client.emit("modifiedPlayer", this.me);
    },
    modifiedRoomLock() {
      this.room.isLocked = !this.room.isLocked
      console.log("Room isLocked = "+this.room.isLocked)
      this.$socket.client.emit("modifiedRoomLock", this.room.isLocked);
    },
    kickedPlayer(username){
      this.$socket.client.emit("kickedPlayer", username);
      delete this.players[username]
    },
  },

  mounted() {
    this.fromHomePage = this.$route.params.fromHomePage
    this.room.id = this.$route.params.roomID

    // if we are coming from the homePage = we load Everything onMount
    if(this.fromHomePage){
      this.username = this.$route.params.username
      this.room = JSON.parse(this.$route.params.room)
      this.players = JSON.parse(this.$route.params.players)
      this.me = this.players[this.username]
    }
  },

  beforeUnmount(){
    document.activeElement.blur(); // to fix bootstrap-js:Checkbox unmounting while focused
  }
}
</script>

<style scoped>
  button {
    margin: 10px;
  }
  h2 {
    margin-top: 30px;
  }
</style>