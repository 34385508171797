<template>
  <h1>Welcome to Elements</h1>
  <h2>(Alpha V2)</h2>
  <p>You can either join a room or create a room (tutorial wip)</p>
  <b-alert v-model="showError" variant="danger" dismissible>{{ errorMessage }}</b-alert>
  <div class="flexbox-container">
    <div class="flexbox-item">
      <h3>Join Room</h3>
      <input type="text" placeholder="Username" v-model="username" required><br>
      <input placeholder="RoomID" v-model="roomID"><br>
      <b-button variant="primary" @click="clickedJoinRoom" :disabled="buttonDisabled && !$socket.connected">Join Room</b-button>
    </div>
    <div class="flexbox-item">
      <h3>Create Room</h3>
      <input type="text" placeholder="Username" v-model="username" required><br>
      <b-button variant="primary" @click="clickedCreateRoom" :disabled="buttonDisabled && !$socket.connected">Create Room</b-button>
    </div>
  </div>
  
  <!-- <img alt="Creep" src="@/assets/creep.png" width="150"> -->
</template>

<script>


export default {
  name: 'Home',
  props: ['errorMessage'],

  data(){
    return {
      buttonDisabled: true,
      username: '',
      roomID: '',
      showError: false,
    }
  },

  sockets: {
    connect() {console.log("connected..."); this.buttonDisabled = false},
    createRoom(room, players) {
      console.log("Room Created with RoomID= "+room.id)
      this.$router.push({ name: 'Room', params: { roomID: room.id, fromHomePage:true, username:this.username, room:JSON.stringify(room), players: JSON.stringify(players)}})
    },
    joinRoom(room, players) {
      console.log("joinRoom with playersData= "+players)
      this.$router.push({ name: 'Room', params: { roomID:room.id, fromHomePage:true, username:this.username, room:JSON.stringify(room), players: JSON.stringify(players)}})
    },
    errorMessage(errorMessage){
      console.log("ERROR: "+errorMessage)
      this.showError = true
      this.$router.push({name: 'Home', params: { errorMessage: errorMessage}})
    }
  },
  
  methods: {
    clickedJoinRoom(){
      if(this.username){
        this.buttonDisabled = true
        this.$socket.client.emit('joinRoom', this.username, this.roomID)
      }
    },
    clickedCreateRoom(){
      if(this.username){
        this.buttonDisabled = true
        this.$socket.client.emit('createRoom', this.username)
      }
    }
  },

  mounted(){
    if(this.errorMessage){
      console.log("Mounted with error")
      this.showError = true
    }
  }
}
</script>

<style scoped>
  .flexbox-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    margin-top: 50px;
  }

  .flexbox-item {
    /* background-color: gray; */
    width: 300px;
    padding: 20px;
    border-style: solid;
  }

  input {
    margin: 5px;
  }

  h2 {
    color: blue;
  }
</style>