<template>
    <div v-if="isMe" class="flexbox-container" :style="bgc">
        <label class="isMe">{{ player.isOwner ? '👑':'' }} {{ player.username }}</label>
        <select @change="changedTeam($event)" ref="selectTeam">
            <option  v-for="team in teams" :key="team.id" :value="team.id">{{ team.text }} {{ team.icon }}</option>
        </select>
        <select @change="changedClass($event)" ref="selectClass">
            <option v-for="classs in classes" :key="classs.id" :value="classs.id">{{ classs.text }} {{ classs.icon }}</option>
        </select>
        <!-- <input type="checkbox" @change="changedReady($event)" v-model="readyCheck"> -->
        <b-form-checkbox v-model="readyCheck" @change="changedReady" switch></b-form-checkbox>
        <label>{{ player.isReady ? "✅ Ready" : "❌ Not Ready" }}</label>
    </div>
    <div v-if="!isMe" class="flexbox-container" :style="bgc">
        <b-button variant="danger" v-if="imOwner" @click="kickedPlayer">Kick</b-button>
        <label>{{ player.isOwner ? '👑':'' }} {{ player.username }}</label>
        <label>{{ teams[player.team].text }} {{ teams[player.team].icon }}</label>
        <label>{{ classes[player.class].text }} {{ classes[player.class].icon }}</label>
        <label>{{ player.isReady ? "✅ Ready" : "❌ Not Ready" }}</label>
    </div>
</template>

<script>

export default {
  name: "PlayerInRoom",
  props: { isMe: Boolean, imOwner: Boolean, player: Object},
  emits: ["changedData", "kickedPlayer"], // sends to parent component that data has been changed !

  data() {
    return {
        classes: {
            sword: {id:'sword', text:'Sword', icon:'⚔️'},
            bow: {id:'bow', text:'Bow', icon:'🏹'},
            shield: {id:'shield', text:'Shield', icon:'🛡️'}},
        teams: {
            blue: {id:'blue', text:'Blue', icon:'🔵'},
            red:{id:'red', text:'Red', icon:'🔴'},
            green:{id:'green', text:'Green', icon:'📗'},
            pink:{id:'pink', text:'Pink', icon:'💗'}},
        bgc: {backgroundColor: ''},
        readyCheck: false,
    }
  },

  methods: {
    changedTeam(event){
        this.player.team = event.target.value
        this.bgc.backgroundColor = this.player.team
        this.player.isReady = false
        this.readyCheck = false
        this.$emit('changedData')
    },

    changedClass(event){
        this.player.class = event.target.value
        this.player.isReady = false
        this.readyCheck = false
        this.$emit('changedData')
    },

    changedReady(){
        this.player.isReady = this.readyCheck
        this.$emit('changedData')
    },

    kickedPlayer(){
        this.$emit('kickedPlayer', this.player.username)
    },
  },

  mounted(){
      console.log("PlayerInRoom.vue - mounted !")
      this.bgc.backgroundColor = this.player.team
      if(this.isMe){
        this.$refs.selectTeam.value = this.player.team
        this.$refs.selectClass.value = this.player.class
      }
  },

  updated(){
      // whenever player data changes from server (we update the background)
      this.bgc.backgroundColor = this.player.team
      console.log("PlayerInRoom.vue - updated !")
      console.log("player = "+JSON.stringify(this.player))
  }
}
</script>

<style scoped>
    .isMe{
        font-weight: bold;
    }

    .flexbox-container {
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        border-style: solid;
        border-color: black;
        
    }

    div.flexbox-container > * {
        /* background-color: gray; */
        /* width: 300px; */
        margin: 20px;
        /* color: red; */
        /* border-style: solid; */
    }

    label {
        color:white;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    /* input[type=checkbox] {
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
        padding: 10px;
    } */
</style>